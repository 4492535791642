<template>
  <div>
    <section class="hero">
      <div class="hero-head">
        <div class="movie-backdrop-container">
          <img class="movie-trailer" :src="image(info.backdrop_path, 'backdrop')" />
        </div>

        <trailer @close-trailer="showTrailer = false" v-if="showTrailer" :videokey="trailerKey"></trailer>
        <div style="position: absolute; top: 7rem; width: 100%; text-align:center ">
          <a
            v-if="info.videos.results.length > 0"
            @click="showTrailer = true"
            class="button is-primary is-rounded is-outlined"
            style="border-width: 2px;"
          >
            <font-awesome-icon style="margin-right: 10px" icon="play"></font-awesome-icon>Preview
          </a>
        </div>
      </div>
      <div class="hero-body movie-content container is-fluid">
        <div class="columns is-mobile is-multiline">
          <poster :imageurl="info.poster_path"></poster>
          <posterinfo
            :info="info"
            :favorite="favorite"
            :watchlist="watchlist"
            :isSignedIn="isSignedIn"
            @handle-favorites="handleFavoriteClick()"
            @handle-watchlist="handleWatchlistClick()"
          ></posterinfo>
          <div class="column is-12"></div>

          <div v-if="isSignedIn" class="column is-11 is-hidden-desktop is-hidden-tablet">
            <div class="level is-mobile">
              <div class="level-left">
                <button
                  @click="handleFavoriteClick()"
                  class="button is-rounded is-link"
                  :class="{favorited: favorite }"
                  title="Favorite/Unfavorite this item. This will update your viewing preferences to help recommend movies and TV shows that you might like"
                >
                  <font-awesome-icon v-if="!favorite" icon="heart"></font-awesome-icon>
                  <font-awesome-icon v-else icon="check"></font-awesome-icon>

                  <p style="margin-left: 10px">Favorite</p>
                </button>
              </div>
              <div class="level-right">
                <button
                  @click="handleWatchlistClick()"
                  title="Add/Remove from your watchlist"
                  class="button is-rounded is-link"
                  :class="{ watchlisted: watchlist }"
                >
                  <font-awesome-icon v-if="!watchlist" icon="plus"></font-awesome-icon>
                  <font-awesome-icon v-else icon="check"></font-awesome-icon>

                  <p style="margin-left: 10px">Watchlist</p>
                </button>
              </div>
            </div>
          </div>
          <plot :plot="info.overview"></plot>

          <episodes :id="id" :name="info.name" :seasons="info.seasons"></episodes>

          <div class="column is-12" v-if="info.vote_count > 0 || reviews.length > 0">
            <p class="title is-4">Reviews</p>
          </div>
          <div class="column is-11-desktop is-12-mobile" v-if="info.vote_count > 0">
            <div class="level">
              <div class="level is-left">
                <p
                  class="title"
                  style="display: inline-block; font-size: 7rem; font-weight: 700;"
                >{{ info.vote_average }}</p>
                <p
                  style="display: inline-block; padding-left: 1rem; font-weight: 600;"
                  class="subtitle is-6"
                >
                  of 10
                  <span style="font-weight: 300">({{ info.vote_count }})</span>
                </p>
              </div>
              <div class="level is-right">
                <p style="font-variant: small-caps">{{ reviews.length | reviewsOrReview}}</p>
              </div>
            </div>
          </div>
          <div
            v-if="isSignedIn && info.seasons.every(season => season.episode_count > 0)"
            class="column is-12-mobile is-11-desktop is-11-tablet"
          >
            <rating :ratingdata="theratingdata"></rating>
          </div>
          <div v-if="reviews.length > 0" class="column is-12">
            <reviewcards v-show="reviews.length > 0" :reviews="reviews.length">
              <reviewcard v-for="(review, index) in reviews" :review="review" :key="index"></reviewcard>
            </reviewcards>
          </div>

          <div
            v-show="info.similar.results.length > 0"
            class="column is-12"
            style="margin-top: 3rem; margin-bottom: 2rem"
          >
            <p class="title is-4">Similar</p>
          </div>
          <div v-show="info.similar.results.length > 0" class="column is-12">
            <imageslider>
              <imagebutton
                v-for="(tvshow, index) in similar"
                :key="index"
                :path="tvshow.poster_path | image"
                to="tv"
                :id="tvshow.id"
              ></imagebutton>
            </imageslider>
          </div>

          <div
            v-show="info.credits.cast.length > 0"
            class="column is-12"
            style="margin-top: 3rem; margin-bottom: 2rem"
          >
            <p class="title is-4">Cast</p>
          </div>
          <div v-show="info.credits.cast.length > 0" class="column is-12">
            <imageslider>
              <captionedimage
                v-for="(person, index) in info.credits.cast"
                :key="index"
                :path="person.profile_path"
                to="person"
                :id="person.id"
                :caption="person.name + ' as ' + person.character"
              ></captionedimage>
            </imageslider>
          </div>

          <div
            v-show="info.credits.crew.length > 0"
            class="column is-12"
            style="margin-top: 3rem; margin-bottom: 2rem"
          >
            <p class="title is-4">Crew</p>
          </div>
          <div v-show="info.credits.crew.length > 0" class="column is-12">
            <imageslider>
              <captionedimage
                v-for="(person, index) in formatCrew(info.credits.crew)"
                :key="index"
                :path="person.profile_path"
                to="person"
                :id="person.id"
                :caption="person.name + ': ' + person.job"
              ></captionedimage>
            </imageslider>
          </div>

          <div
            v-if="info.videos.results.length > 0"
            class="column is-12"
            style="margin-top: 3rem; margin-bottom: 2rem"
          >
            <p class="title is-4">Trailers</p>
          </div>
          <div v-if="info.videos.results.length > 0" class="column is-12">
            <trailers :videos="info.videos.results"></trailers>
          </div>

          <div class="column is-12" style="margin-top: 3rem; margin-bottom: 2rem">
            <p class="title is-4">More info</p>
          </div>
          <div class="column is-12-mobile is-11-desktop">
            <phlix-details :info="info"></phlix-details>
          </div>

          <div
            v-if="info.production_companies.length > 0"
            class="column is-12"
            style="margin-top: 3rem; margin-bottom: 2rem"
          >
            <p class="title is-5">Production companies</p>
          </div>
          <div
            v-if="info.production_companies.length > 0"
            class="column is-12-mobile is-11-desktop"
          >
            <production :production="info.production_companies"></production>
          </div>
        </div>

        <phlix-footer></phlix-footer>
      </div>
    </section>
    <bottomnavbar></bottomnavbar>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";
import Poster from "@/components/movies/Poster.vue";
import Posterinfo from "@/components/tv/Posterinfo.vue";
import Plot from "@/components/movies/Plot.vue";
import Reviewcard from "@/components/movies/Reviewcard.vue";
import Reviewcards from "@/components/movies/Reviewcards.vue";
import Imageslider from "@/components/Imageslider.vue";
import Captionedimage from "@/components/Captionedimage.vue";
import Imagebutton from "@/components/Imagebutton.vue";
import Trailer from "@/components/Trailer.vue";
import Details from "@/components/tv/Details.vue";
import Error from "@/components/Error.vue";
import Production from "@/components/tv/Production.vue";
import Spokenlangs from "@/components/Spokenlangs.vue";
import Releasedates from "@/components/movies/Releasedates.vue";
import Trailers from "@/components/movies/Trailers.vue";
import Footer from "@/components/Footer.vue";
import Episodes from "@/components/tv/Episodes.vue";
import Rating from "@/components/Ratingstraight.vue";
import Bottomnavbar from "@/components/Bottomnavbar";

import { Bus } from "@/utils/Bus.js";

const tmdb = new TMDb();
Window.tmdb = tmdb;

export default {
  name: "tv",
  props: ["id"],
  components: {
    Poster,
    Posterinfo,
    Plot,
    Reviewcard,
    Reviewcards,
    Imageslider,
    Captionedimage,
    Imagebutton,
    Trailer,
    Error,
    "phlix-details": Details,
    "phlix-footer": Footer,
    Production,
    Spokenlangs,
    Releasedates,
    Trailers,
    Episodes,
    Rating,
    Bottomnavbar,
  },
  data() {
    return {
      info: {},
      reviews: [],
      showTrailer: false,
      errorIsOpen: false,
      errorMsg: "Uh-oh :(! We've ran into a problem",
      favorite: false,
      watchlist: false,
      theratingdata: {},
      rating: 0,
      isSignedIn: false,
      notfound: require("@/assets/images/unknown.png"),
    };
  },
  mounted() {
    Bus.$on("signedoff", () => (this.isSignedIn = false));
    this.fetchAllData();
  },
  watch: {
    id() {
      this.info.backdrop_path = null;
      this.fetchAllData();
      Bus.$emit("resetRating");
      Bus.$emit("scroll");
    },
  },
  methods: {
    image(url, type) {
      let beginning = "https://image.tmdb.org/t/p/";
      return url != null
        ? type == "backdrop"
          ? beginning + "w1280/" + url
          : beginning + "w500/" + url
        : this.notfound;
    },
    fetchAllData() {
      this.isSignedIn = tmdb.isSignedIn();
      tmdb
        .getTv(this.id)
        .then((resp) => {
          this.info = resp.data;
          if(this.info.vote_average) this.info.vote_average = this.info.vote_average.toFixed(1)
          if (this.isSignedIn) {
            tmdb.getTvAccountStates(this.id).then((resp) => {
              this.favorite = resp.data.favorite;
              this.watchlist = resp.data.watchlist;
              resp.data.rated.value == undefined
                ? (this.rating = 0)
                : (this.rating = resp.data.rated.value);
              console.log(resp.data.rated.value);
              this.theratingdata = this.ratingdata();
            });
          }
        })
        .catch((err) => {
          this.errorIsOpen = true;
          this.errorMsg = err;
        });
      tmdb
        .getTvReviews(this.id)
        .then((resp) => (this.reviews = resp.data.results))
        .catch((err) => {
          this.errorIsOpen = true;
          this.errorMsg = err;
        });
    },
    concatCredits(data) {
      var result = _(data)
        .groupBy("id")
        .map((array) => ({
          ...array[0],
          job: _.join(_.map(array, "job"), ", "),
        }))
        .value();
      return result;
    },
    formatCrew(crew) {
      return _.sortBy(
        this.concatCredits(
          _.sortBy(
            crew,
            (person) =>
              person.job === "Director"
                ? 0
                : 1 /* moves the director caption to the beginning of the list of jobs */
          )
        ),
        (person) =>
          person.job.split(", ").includes("Director")
            ? 0
            : 1 /* moves the director picture to the beginning */
      );
    },
    handleFavoriteClick() {
      this.favorite == false ? (this.favorite = true) : (this.favorite = false);
      tmdb.processFavorites(this.id, "tv", this.favorite);
    },

    handleWatchlistClick() {
      this.watchlist == false
        ? (this.watchlist = true)
        : (this.watchlist = false);
      tmdb.processWatchlist(this.id, "tv", this.watchlist);
    },

    ratingdata() {
      let data = {
        id: this.id,
        type: "tv",
        previous: this.rating / 2, //divided by two to convert from a 10 star to 5 star rating system
      };

      return data;
    },
  },
  computed: {
    redirectName() {
      return "tv";
    },
    similar() {
      return _.filter(this.info.similar.results, (image) => image.poster_path);
    },
    videoswithoutduplicates() {
      return _.remove(
        this.info.videos.results,
        (video) => video.site == "YouTube"
      ); /* removes duplicates */
    },
    trailerKey() {
      var sorted = _.sortBy(
        this.videoswithoutduplicates,
        (video) =>
          video.type == "Trailer"
            ? 0
            : 1 /* moves the Trailers to the beginning */
      );
      this.info.videos.results = sorted;
      return sorted[0].key;
      // return "-BgWppq25xI";
    },
  },
  filters: {
    reviewsOrReview(length) {
      return length == 1 ? length + " review" : length + " reviews";
    },

    image(url) {
      return url != null
        ? "https://image.tmdb.org/t/p/w500/" + url
        : require("@/assets/images/unknown.png");
    },
  },
};
</script>

<style scoped>
.movie-trailer {
  width: 100%;
  height: auto;
}

.movie-backdrop-container {
  max-height: 25em;
  overflow: hidden;
  -webkit-mask: linear-gradient(to bottom, black, transparent);
  mask: linear-gradient(to bottom, black, transparent);
}

.movie-content {
  z-index: 99;
  position: absolute;
  top: 16rem;
}

.favorited {
  background-color: pink !important;
}

.watchlisted {
  background-color: dodgerblue !important;
}

.column {
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>